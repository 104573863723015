import React, { useContext, useEffect } from "react"
import AuthContext from '../../../context/AuthProvider';

import Layout from "../../organisms/Layout/Layout"
import Seo from "../../organisms/Seo/Seo"
import Container from "../../atoms/Container/Container"
import Breadcrumbs from "../../atoms/Breadcrumbs/Breadcrumbs";
import PageTitle from "../../atoms/PageTitle/PageTitle"
import AccountPageMenu from '../../molecules/AccountPageMenu/AccountPageMenu'

import * as styles from './AccountPageWrapper.module.css'

const AccountPageWrapper = ({ children, metaTitle, title }) => {
    const auth = useContext(AuthContext);
    const userChecked = auth && auth.state.userChecked;
    const isLoggedIn = auth && auth.state.isLoggedIn;
    const customerId = auth && auth.state.customerId;

    useEffect(() => {
        // console.log(fieldErrors);
        if (userChecked && !isLoggedIn && typeof window !== 'undefined') {
            window.location = '/sign-in';
        }
    }, [isLoggedIn, userChecked])

    if (isLoggedIn) {
        const childPage = React.Children.map(children, child => {
            if (React.isValidElement(child)) {
                return React.cloneElement(child, { customerId })
            }

            return child
        })

        return (
            <Layout>
                <Seo title={`Account - ${metaTitle}`} />
                <Container size="large">
                    <div className={styles.breadcrumbWrap}>
                        <Breadcrumbs crumbs={[{link: '/', label:'Home'}, {link: '/account/', label:'Account'}, {label:`${title}`}]} />
                    </div>
                    
                    <div className={`grid grid-1585 ${styles.gridAccount}`}>
                        <div className={styles.sideMenu}>
                            <AccountPageMenu />
                        </div>
                        <div>
                            <PageTitle title={title} />
                            { childPage }
                        </div>
                    </div>
                </Container>
            </Layout>
        )
    } else {
        return (
            <Layout>
                <Seo title="Sign in" />
                <Container>
                    
                </Container>
            </Layout>
        )
    }
}

export default AccountPageWrapper