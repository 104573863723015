import React, { useEffect, useState, useContext } from 'react';
import { head, isArray } from 'lodash';
import { bcApi } from '../../helpers/bigcommerce'
import CartContext from '../../context/CartProvider';

import AccountPageWrapper from "../../components/organisms/AccountPageWrapper/AccountPageWrapper"
import CurrencyFormatter from "../../components/atoms/CurrencyFormatter/CurrencyFormatter"
import DateFormatter from "../../components/atoms/DateFormatter/DateFormatter"
import BillingAddress from '../../components/atoms/BillingAddress/BillingAddress';
import ReturnsForm from '../../components/organisms/ReturnsForm/ReturnsForm';
import Icon from "../../components/atoms/Icon/Icon"
import Button from '../../components/atoms/Button/Button';
import Dialog from '../../components/atoms/Dialog/Dialog';

import * as styles from './orders.module.css';

const fetchProductImage = productId => {
    const endpoint = `catalog/products/${productId}/images`;
    return bcApi(endpoint).then(({ response }) => {
        if (isArray(response.data)) {
        const thumb = response.data.filter(a => a.is_thumbnail === true);
        return head(thumb.length ? thumb : response.data);
        }
        return {};
    });
};

const fetchProducts = async orderId => {
    const productsEndpoint = `orders/${orderId}/products`;
    const products = await bcApi(productsEndpoint, 'GET', null, 2).then(
        ({ response, status }) => {
        if (!response || status !== 200) {
            return [];
        }
        return response;
        }
    );

    const promises = products.map(async product => {
        const image = await fetchProductImage(product.product_id);
        return { ...product, image };
    });

    return Promise.all(promises);
};

const fetchShipping = async orderId => {
    const endpoint = `orders/${orderId}/shipping_addresses`;
    const data = await bcApi(endpoint, 'GET', null, 2).then(
        ({ response, status }) => {
        if (status === 200 && response) {
            return response;
        }
        return [];
        }
    );
    return data;
};

const fetchOrders = async customerId => {
    const endpoint = `orders?customer_id=${customerId}&sort=date_created:desc`;
    const orders = await bcApi(endpoint, 'GET', null, 2).then(
        ({ response, status }) => {
        if (!response || status !== 200) {
            return [];
        }
        return response;
        }
    );

    const promises = orders.map(async order => {
        const products = await fetchProducts(order.id);
        const shipping = await fetchShipping(order.id);
        return { ...order, products, shipping_addresses: head(shipping) };
    });

    return Promise.all(promises);
};

const Orders = ({ customerId }) => {
    const [orders, setOrders] = useState([]);
    const [activated, setActivated] = useState({});
    const [fetched, toggleFetched] = useState(false);
    const [openReturns, toggleOpenReturns] = useState(false);
    const [reorderError, setReorderError] = useState(false);
    const cartCtx = useContext(CartContext);
    const addAllToCart = cartCtx && cartCtx.addAllToCart;

    const getOrderData = async () => {
        toggleFetched(false);
        setOrders(await fetchOrders(customerId));
        toggleFetched(true);
    };

    useEffect(() => {
        if (!fetched && customerId > 0) {
          getOrderData();
        }
        // eslint-disable-next-line
    }, [fetched, customerId]);

    const toggleViewOrder = order => () => {
        if (activated.id !== order.id) {
          setActivated(order);
        } else {
          setActivated({});
        }
    };
    
    const getFirstProductImage = order => {
        if (order) {
          if (order.products && order.products?.length !== 0) {
            const image = head(order.products).image;
            return (
              <img loading="lazy" src={image.url_thumbnail} alt={image.description} />
            )
          }
        }
        return null;
    };
    
    const reorder = (e, orderData) => {
        e.target.innerText = "Adding...";
        addAllToCart(orderData.products).then(response => {
          if (typeof window !== "undefined") {
            window.location = '/cart';
            // window.location = '/confirm-order/';
          }
        }).catch(e => {
          if ('title' in e && e.title.indexOf('does not have sufficient stock') > -1) {
            setReorderError("One or more of the products in this order are currently out of stock. Please add products individually.");
          } else if ('title' in e && e.title.indexOf('No products to add') > -1) {
            setReorderError("There are no products above that can be added. For example, gift cards are not able to be reordered.");
          } else {
            setReorderError("An error occurred while trying to add the products from the order to cart. Please add products individually.");
          }
        });
    }

    const clearReorderDialog = () => {
      setReorderError(false);
    }

    return (
        <div className={styles.root}>
          {fetched && orders && orders.length === 0 && (
            <span>You have no orders</span>
          )}
    
          {fetched && orders && orders.length > 0 && (
            <div className={`dataTable ${styles.dataTable}`}>
              <div className={styles.tableHeader}>
                <div data-orderid>
                  <span>Order #</span>
                </div>
                <div>
                  <span>Order Placed</span>
                </div>
                <div>
                  <span>Last Update</span>
                </div>
                <div data-status>
                  <span>Status</span>
                </div>
                <div data-toggle></div>
              </div>
              {orders.map(order => (
                <React.Fragment key={order.id}>
                {order.status_id > 0 && (
                  <div className={styles.tableRow}>
                    <div className={styles.orderItem} role="presentation" onClick={toggleViewOrder(order)}>
                      <div data-productimage>{getFirstProductImage(order)}</div>
                      <div data-orderid>
                        <div className={styles.bold}>
                          Order #{order.id}
                        </div>
                        <div>
                          {order.items_total} product
                          {order.items_total > 1 ? 's' : ''} totaling{' '}
                          <CurrencyFormatter
                            currency={order.currency_code}
                            amount={order.total_inc_tax}
                            ignoreTax={true}
                          />
                        </div>
                      </div>
                      <div data-orderplaced>
                        <div className={styles.bold}>Order Placed</div>
                        <DateFormatter date={order.date_created} />
                      </div>
                      <div data-lastupdated>
                        <div className={styles.bold}>Last Update</div>
                        <DateFormatter date={order.date_modified} />
                      </div>
                      <div data-status>
                        <div className={styles.bold}>Status</div>
                        <span>{order.status}</span>
                      </div>
                      <div data-toggle className={activated.id === order.id ? styles.open : ''}>
                        <Icon symbol='caretDown' />
                      </div>
                    </div>
    
                    {activated.id === order.id && (
                      <div className={styles.orderDetail}>
                        <div className="show">
                          <div className={styles.orderBillingAddress}>
                            <BillingAddress
                              title="Ship to"
                              address={activated.shipping_addresses}
                            />
                            <BillingAddress
                              title="Bill to"
                              address={activated.billing_address}
                            />
                          </div>
                          <div className={styles.orderDetailTable}>
                            {activated.products?.map(product => {
                              return (
                                <div
                                  key={product.product_id}
                                  className={styles.orderDetailRow}
                                >
                                  <div>
                                    <img
                                      loading="lazy"
                                      src={product.image.url_thumbnail}
                                      alt={product.image.description}
                                    />
                                  </div>
                                  <div>
                                    <div className={styles.bold}>{product.name}</div>
                                    <div>Quantity: {product.quantity}</div>
                                    {product.product_options.length > 0 &&
                                      product.product_options.map(
                                        (option, optionIndex) => (
                                          <div key={optionIndex}>
                                            {option.display_name}:
                                            {option.display_value}
                                          </div>
                                        )
                                      )}
                                  </div>
                                  <div className={`flex-end ${styles.bold}`}>
                                    <CurrencyFormatter
                                      currency={order.currency_code}
                                      amount={product.total_inc_tax}
                                      ignoreTax={true}
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <div className={styles.summary}>
                            <div className={styles.summaryActions}>
                              <div className="flex-between">
                                <Button level="secondary" type="button" onClick={(e) => reorder(e, order)}>Reorder</Button>
                                <Dialog open={reorderError ? true : false} title="Unable to reorder" size="sm" hideBtnCancel disableBackdropClick onOk={() => clearReorderDialog()}>{reorderError}</Dialog>
                                <Button level="secondary" type="button" onClick={(e) => toggleOpenReturns(!openReturns)}>Return items</Button>
                              </div>
                              <Dialog title="Returns request" hideBtnOk={true} cancelBtnText="Close" open={openReturns} onCancel={() => toggleOpenReturns(false)}>
                                <ReturnsForm orderDetails={order} />
                              </Dialog>
                            </div>
                            <div className={styles.summaryInner}>
                              <div>
                                <span>Subtotal:</span>
                                <span className={styles.bold}>
                                  <CurrencyFormatter
                                    currency={order.currency_code}
                                    amount={order.subtotal_inc_tax}
                                    ignoreTax={true}
                                  />
                                </span>
                              </div>
                              {order?.coupon_discount && order?.coupon_discount > 0 && (
                                <div>
                                  <span>Coupon discount:</span>
                                  <span className={styles.bold}>
                                    <CurrencyFormatter
                                      currency={order.currency_code}
                                      amount={order.coupon_discount}
                                      ignoreTax={true}
                                    />
                                  </span>
                                </div>
                              )}
    
                              {order?.gift_certificate_amount && order?.gift_certificate_amount > 0 && (
                                <div>
                                  <span>Gift certificate:</span>
                                  <span className={styles.bold}>
                                    <CurrencyFormatter
                                      currency={order.currency_code}
                                      amount={order.gift_certificate_amount}
                                      ignoreTax={true}
                                    />
                                  </span>
                                </div>
                              )}
    
                              <div>
                                <span>Shipping:</span>
                                <span className={styles.bold}>
                                  <CurrencyFormatter
                                    currency={order.currency_code}
                                    amount={order.shipping_cost_inc_tax}
                                    ignoreTax={true}
                                  />
                                </span>
                              </div>
    
                              <div>
                                <span>GST:</span>
                                <span className={styles.bold}>
                                  <CurrencyFormatter
                                    currency={order.currency_code}
                                    amount={order.total_tax}
                                    ignoreTax={true}
                                  />
                                </span>
                              </div>
    
                              <div>
                                <span>Grand Total:</span>
                                <span className={styles.bold}>
                                  <CurrencyFormatter
                                    currency={order.currency_code}
                                    amount={order.total_inc_tax}
                                    ignoreTax={true}
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                </React.Fragment>
              ))}
            </div>
          )}
    
          {!fetched && <span>Fetching your order history...</span>}
        </div>
    );
    
}

const OrdersOutput = () => (
    <AccountPageWrapper metaTitle="Account - Orders" title="Orders">
        <Orders />
    </AccountPageWrapper>
)

export default OrdersOutput