import React from 'react'

import * as styles from './PageTitle.module.css'

const PageTitle = ({ children, title, titleClass, customMode = false }) => {
  return (
    <div data-pagetitle className={styles.root}>
        {/* <Container> */}
          {customMode ? (
            <h1 className={styles.customTitleClass}>{children}</h1>
          ) : (
            <>
              <h1 className={titleClass}>{title}</h1>
              {children && (
                  <div className={styles.subtitle}>{children}</div>
              )}
            </>
          )}
        {/* </Container> */}
    </div>
  )
}

export default PageTitle
